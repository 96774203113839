import React, { useEffect } from 'react';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { firebaseConfig, vapidKey } from './firebase/firebaseConfig'

import { useRecoilValue } from "recoil";
import { loginEmailState } from "./atom";

function firebaseGetToken(messaging, vapidKey) {
    getToken(messaging, { vapidKey: vapidKey }).then((currentToken) => {
        if (currentToken) {
            // 取得 currentToken，需傳回 server
            console.log('get currentToken');
            console.log(currentToken);
        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}

function Home() {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    // messaging
    const messaging = getMessaging();

    useEffect(() => {
        firebaseGetToken(messaging, vapidKey);
    }, []);

    // 要求 notification 授權
    if ('Notification' in window) {
        console.log('Notification.permission:' + Notification.permission);
        if (Notification.permission !== 'granted') {
            console.log('Ask user permission')
            Notification.requestPermission(status => {
                console.log('Status:' + status)
            });
        }
    }

    onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        const notificationTitle = 'Message Title';
        const notificationOptions = {
            body: 'Message body.',
            icon: '/logo192.png'
        };
        new Notification(notificationTitle,
            notificationOptions)
    });

    const loginEmail = useRecoilValue(loginEmailState);

    return (
        <div>
            這是首頁，您好, {loginEmail}
        </div>
    );
}

export default Home;