import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import HomeIcon from '@mui/icons-material/Home';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilState } from 'recoil';
import { isLoginState, loginEmailState } from "./atom";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
    getAuth,
    onAuthStateChanged,
    signOut
} from "firebase/auth";
import { firebaseConfig } from './firebase/firebaseConfig'
import { stringAvatar } from './utils/utils';

const drawerWidth = 240;

export default function ResponsiveDrawer(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth();

    // auth
    const [isLogin, setIsLogin] = useRecoilState(isLoginState);
    const [loginEmail, setLoginEmail] = useRecoilState(loginEmailState);

    const navigate = useNavigate()

    const checkLogin = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                // const uid = user.uid;
                // ...
                setIsLogin(true);
                setLoginEmail(user.email);
            } else {
                // User is signed out
                // ...
                setIsLogin(false);
            }
        });
    }

    const logoutUser = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            setIsLogin(false);
        }).catch((error) => {
            // An error happened.
        });
    }

    const loginUser = () => {
        navigate('/login');
    }

    useEffect(() => {
        checkLogin();
    }, []);

    let accountDisplay = [];
    if (isLogin) {
        accountDisplay = (
            <div>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <Tooltip title={loginEmail}>
                        <Avatar {...stringAvatar(loginEmail)} sx={{ width: 32, height: 32 }} />
                    </Tooltip>
                </IconButton>
                <IconButton
                    size="large"
                    aria-label="logout"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={logoutUser}
                    color="inherit"
                >
                    <LogoutIcon />
                </IconButton>
            </div>
        )
    } else {
        accountDisplay = (
            <div>
                <IconButton
                    size="large"
                    aria-label="login"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={loginUser}
                    color="inherit"
                >
                    <Typography>
                        <span style={{ fontSize: '1rem' }}>登入</span>
                    </Typography>
                    <LoginIcon />
                </IconButton>
            </div>
        )
    }

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                <ListItem key="home" disablePadding>
                    <ListItemButton>
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary="Home" onClick={() => {
                            navigate('/');
                        }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="todo" disablePadding>
                    <ListItemButton>
                        <ListItemIcon><FactCheckIcon /></ListItemIcon>
                        <ListItemText primary="Todo List" onClick={() => {
                            navigate('/todolist');
                        }} />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        havoc Firebase 測試專案
                    </Typography>
                    {accountDisplay}
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Typography paragraph>
                    <Outlet />
                </Typography>
            </Box>
        </Box>
    );
}
