export const firebaseConfig = {
    apiKey: "AIzaSyBRwazIekzUo91gZhSAY4OL-NBBEjcIuJw",
    authDomain: "havocfire-9c943.firebaseapp.com",
    projectId: "havocfire-9c943",
    storageBucket: "havocfire-9c943.appspot.com",
    messagingSenderId: "745548018460",
    appId: "1:745548018460:web:87a4f3bb2fec5c0ea940d9",
    measurementId: "G-VLNTFBJH7R"
};

export const vapidKey = 'BHweFHCGGgYPz5SHc3T9rdv2dFy7qfKJhIPRHw1ShnsCT3SaI2kwrrw5sZcEH6KfJrUOatPfifjOXzoAkg_ozOk';