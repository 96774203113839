import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GoogleIcon from '@mui/icons-material/Google';

import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { isLoginState } from "./atom";

import './App.css';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
    getAuth,
    signInWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider
} from "firebase/auth";

import { firebaseConfig } from './firebase/firebaseConfig'

import Copyright from './copyright';

const theme = createTheme();

function Login() {

    const navigate = useNavigate()

    const [alertMsg, setAlertMsg] = useState(false);

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth();
    // auth.languageCode = 'zh-TW';
    const provider = new GoogleAuthProvider();

    const googleLogin = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                console.log('login success');
                console.log(token);
                console.log(user);
                navigate('/');
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                console.log('login fail');
                console.log(errorCode);
                console.log(errorMessage);
                console.log(email);
                console.log(credential);
                setAlertMsg(errorMessage);
            });
    }

    const { control, handleSubmit } = useForm({
        defaultValues: {
            email: '',
            password: ''
        }
    });
    const onSubmit = data => {
        let email = data.email;
        let password = data.password;
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log('login success');
                console.log(user);
                navigate('/');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('login fail');
                console.log(errorCode);
                console.log(errorMessage);
                setAlertMsg(errorMessage);
            });
    }

    let alertDiv = [];
    if (alertMsg) {
        alertDiv = (
            <Alert severity="error" onClose={() => { setAlertMsg('') }}>{alertMsg}</Alert>
        )
    }

    const isLogin = useRecoilValue(isLoginState);
    useEffect(() => {
        if (isLogin) {
            navigate('/');
        }
    }, [isLogin]);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        登入
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                        {alertDiv}
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    {...field}
                                />
                            }
                        />
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    {...field}
                                />
                            }
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            登入
                        </Button>
                        <Grid container>
                            <Grid item xs>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2" onClick={() => {
                                    navigate('/register');
                                }}>
                                    註冊帳號
                                </Link>
                            </Grid>
                        </Grid>
                        <Button
                            startIcon={<GoogleIcon />}
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={googleLogin}
                        >
                            Google 登入
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}

export default Login;
