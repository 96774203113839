export function stringAvatar(name) {
    let display = '';
    let size = name.split(' ').length;
    if (size >= 1) {
        display = name.split(' ')[0][0] + name.split(' ')[0][1]
    } else {
        display = name.split(' ')[0][0] + name.split(' ')[1][0]
    }
    display = display.toUpperCase();
    return {
        children: display
    };
}